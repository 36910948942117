.steps {
    height: 70px;
    background-color: #f7f7fc;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1000px) {
        display: none;
    }
    .stepsList {
        display: flex;
        justify-content: space-between;
        padding: 0 2rem;
        list-style: none;
        margin: 0;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 22px;
        letter-spacing: 1px;
        width: 100%;
        font-weight: 600;
        color: #a0a3bd;
        li {
            display: flex;
            align-items: center;
        }
    }

    .stepsListItemActive {
        color: #4e4b66;
        .stepsListIndex {
            background-color: #4e4b66;
        }
    }
    .stepsListItemDone {
        color: #7dbd7f;
        .stepsListIndex {
            background-color: #7dbd7f;
        }
    }

    .stepsListIndex {
        color: #f7f7fc;
        background-color: #a0a3bd;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        margin-right: 0.5rem;
        position: relative;
        &Span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .stepsListSeparator {
        letter-spacing: 5px;
        color: #d9dbe9;
        font-size: 9px;
        span {
            padding-left: 5px;
            text-align: center !important;
            display: block;
        }
    }
    .stepsListSeparatorDone {
        color: #7dbd7f;
    }
}
