.details {
    width: 100%;
    padding: 30px 100px;
    @media (max-width: 1000px) {
        padding: 16px;
    }
    > div:first-child {
        margin-top: 0 !important;
    }
}

.feeWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    margin-top: 22px;
}

.feeItemInside {
    background-color: #eff0f6;
    border-radius: 8px;
    width: 100%;
    padding: 4px 24px;
    opacity: 0.5;
    &Text {
        color: #6e7191;
        letter-spacing: 0.1px;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        margin: 0;
    }
}
.feeItemInsideValue {
    color: #14142b;
    letter-spacing: 0.2px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    margin: 0;
}
.feeHint {
    color: #6e7191;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    text-align: right;
}

.scannerError {
    color: #f44747;
    text-align: center;
    margin-bottom: 0;
}

.tooltipItem {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 4px 6px;
    font-size: 14px;
    z-index: 3;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
}

.feeTooltip {
    position: relative;
    &:hover {
        .tooltipItem {
            visibility: visible;
            opacity: 1;
        }
    }
}

.listContainer {
    font-size: 12px;
    font-weight: 400;
    margin-top: 10px;
    p {
        margin: 5px 0;
    }
}