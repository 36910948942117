.pinCodeInfo {
    text-align: center;
    margin: 0;
    font-size: 13px;
    line-height: 19.5px;
    letter-spacing: 0.1px;
    font-weight: 600;
    margin-top: 12px;
    &Success {
        color: #37a23a;
    }
    &Error {
        color: #f44747;
    }
}

.pinCodeError {
    color: #f44747;
}

.pinCodeSuccess {
    color: #37a23a;
}