.dots {
    display: flex;
    li {
        margin: 0;
        width: auto;
        margin-right: 8px;
        cursor: default;
    }
}

.dot {
    border-radius: 4px;
    height: 8px;
    width: 8px;
    background-color: #d9dbe9;
    transition: 0.4s all ease-in-out;
    cursor: pointer;
    &Active {
        background-color: #14142b;
        width: 32px;
    }
}
