.row {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-top: 1px solid #e6e6e6;
    font-size: 14px;
    font-weight: 500;
    color: #14142b;
    &:first-child {
        border-top: none;
    }
    @media (max-width: 450px) {
        font-size: 12px;
    }
}

.value {
    text-align: right;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}
