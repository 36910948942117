.content {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    padding-bottom: 40px;
}

.linkRow {
    display: flex;
    justify-content: flex-end;
    a {
        text-decoration: none;
        color: #0d41e6;
        font-weight: 600;
        text-transform: uppercase;
        margin: 10px 0;
        display: flex;
        align-items: center;
        &:hover {
            text-decoration: underline;
        }
    }
}
