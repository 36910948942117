.summary {
    padding: 0 100px;
    width: 100%;
    margin-top: 24px;
    @media (max-width: 1000px) {
        padding: 0 16px;
        margin-top: 16px;
    }
    &General {
        border: 1px solid #eff0f6;
        border-radius: 8px;
        padding: 8px;
        @media (max-width: 1000px) {
            padding: 8px 16px 16px 16px;
        }
    }
}

.amount {
    text-align: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #eff0f7;
    @media (max-width: 1000px) {
        padding-bottom: 8px;
    }
    &Text {
        font-size: 10px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 1px;
        color: #a0a3bd;
        text-transform: uppercase;
        margin: 0;
    }
    &Value {
        color: #14142b;
        letter-spacing: 1px;
        line-height: 36px;
        font-size: 24px;
        text-transform: uppercase;
        margin: 0;
        font-weight: 600;
    }
}

.network {
    display: flex;
    padding: 4px 0;
    @media (max-width: 1000px) {
        padding: 8px 0;
    }
    p:first-child {
        color: #a0a3bd;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 0;
        line-height: 22px;
    }
    p:last-child {
        color: #4e4b66;
        font-size: 13px;
        letter-spacing: 0.1px;
        margin: 0;
        line-height: 16px;
        font-weight: 500;
        @media (max-width: 1000px) {
            font-size: 11px;
        }
        span {
            color: #a0a3bd;
        }
    }
    &Descriptions {
        border-right: 1px solid #eff0f6;
        padding-right: 68px;
        @media (max-width: 1000px) {
            width: 100%;
        }
    }
    &Fee {
        padding-left: 16px;

        p:nth-child(2) {
            @media (max-width: 1000px) {
                display: flex;
                flex-direction: column;
            }
        }

        @media (max-width: 1000px) {
            width: 100%;
        }
    }
}

.address {
    border-top: 1px solid #eff0f6;
    padding-top: 8px;
    @media (max-width: 1000px) {
        padding-top: 8px;
    }
    p:first-child {
        color: #a0a3bd;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 0;
        line-height: 22px;
    }
    p:last-child {
        color: #4e4b66;
        font-size: 13px;
        letter-spacing: 0.1px;
        margin: 0;
        line-height: 16px;
        font-weight: 500;
        @media (max-width: 1000px) {
            font-size: 11px;
        }
        @media (max-width: 350px) {
            font-size: 10px;
        }
        span {
            color: #a0a3bd;
        }
    }
}

.note {
    padding: 8px 0;
    @media (max-width: 1000px) {
        max-width: 500px;
        padding: 16px 0;
    }
    &Header {
        display: flex;
        align-items: center;
        color: #f44747;
        font-weight: 600;
        font-size: 13px;
        line-height: 19.5px;
        letter-spacing: 0.1px;
        margin: 0;
        margin-bottom: 8px;
        img {
            margin-right: 8px;
        }
    }
    &Text,
    &More {
        color: #f44747;
        font-size: 10px;
        font-weight: 600;
        line-height: 16px;
        margin: 0;
    }
    &More {
        margin-top: 4px;
    }
}

.noteGray {
    padding-top: 0;
    .noteHeader {
        color: #4e4b66;
    }
    .noteText {
        color: #4e4b66;
    }
}
