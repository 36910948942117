.footerContainer {
    @media(max-width: 1000px) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.footer {
    background: #fcfcfc;
    padding: 24px 32px;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eff0f6;
    min-height: 113px;
    @media (max-width: 1000px) {
        flex-direction: column;
        padding: 11px 16px 16px 16px;
        margin-top: 10px;
        min-height: auto;
    }
}



.buttons {
    flex-direction: row;
    justify-content: center;
}

.primary {
    background: #0d41e6;
    color: white;
}

.nextDisable {
    opacity: 0.5;
    cursor: default;
}

.loading {
    height: 28px;
    color: #0d41e6;
    margin: 0 43px;
}
