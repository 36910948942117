.submitBtn {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.btnContainer {
    margin: 25px 0 35px 0;
}

.sendFormButton {
    text-align: center;
} 

.error {
    color: red;
    font-weight: 500;
    margin-top: 10px;
    font-size: 12px;
    display: block;
}

.formSuccess {
    border: 1px solid #0d41e6;
    margin: 20px 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
    h3 {
        color: #0d41e6;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    span {
        font-size: 14px;
        color: #4e4b66;
        font-weight: 500;
    }
}