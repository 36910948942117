.pinCode {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;

    @media (max-width: 400px) {
        width: 100%;
    }
    &Input {
        color: #4e4b66;
        font-weight: 600;
        line-height: 28px;
        font-size: 16px;
        text-align: center;
        width: 47px;
        height: 56px;
        border-radius: 8px;
        border: 2px solid #a0a3bd;
        background: rgba(239, 240, 247, 0.5);
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        &Success {
            border-color: #37a23a;
            background-color: #f4f9f7;
        }
        &Error {
            border-color: #f44747;
            background-color: #fff6fa;
        }
    }
}
