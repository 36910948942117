.endScreen {
    display: flex;
    flex-direction: column;
    @media (max-width: 1000px) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        flex-direction: column;
    }
    &Container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &Close {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        padding: 32px;
        @media (max-width: 1000px) {
            padding: 16px;
        }
        img {
            cursor: pointer;
        }
    }
    &Image {
        width: 100%;
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1000px) {
            padding: 0;
            height: 300px;
            overflow: hidden;
        }
        @media (max-width: 500px) {
            height: 200px;
        }
        @media (max-width: 350px) {
            height: 150px;
        }
        img {
            height: 270px;
            @media (max-width: 1000px) {
                width: 100%;
            }
            @media (max-width: 500px) {
                margin-top: 50px;
            }
            @media (max-width: 350px) {
                margin-top: 100px;
            }
        }
    }
    &Bottom {
        padding: 0 32px;
        @media (max-width: 1000px) {
            padding: 16px;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    &Header {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 1px;
        font-weight: 700;
        color: #14142b;
        margin-bottom: 0;
        margin-top: 32px;
        @media (max-width: 1000px) {
            margin: 0;
            font-size: 22px;
        }
    }
    &Text {
        color: #4e4b66;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.3px;
        margin: 0;
        margin-top: 8px;
        margin-bottom: 32px;
        @media (max-width: 1000px) {
            margin-bottom: 0;
        }
        @media (max-width: 350px) {
            font-size: 12px;
        }
    }
    &Table {
        width: 100%;
        padding: 12px 16px 12px 24px;
        border: 1px solid #eff0f6;
        border-radius: 8px;
        display: flex;
        margin-bottom: 24px;
        height: 84px;
        @media (max-width: 1000px) {
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: auto;
            padding: 0;
        }
        p:first-child {
            font-weight: 600;
            font-size: 10px;
            text-transform: uppercase;
            line-height: 22px;
            margin: 0;
            color: #a0a3bd;
            letter-spacing: 1px;
            @media (max-width: 350px) {
                font-size: 10px;
                letter-spacing: 0.5px;
            }
        }
        p:last-child {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 28px;
            letter-spacing: 0.1px;
            color: #4e4b66;
            display: flex;
            align-items: center;
            @media (max-width: 1000px) {
                font-size: 11px;
            }
        }
        &Item {
            width: 100%;
            text-align: left;
            margin: auto 0;
            padding: 8px 0;
            @media (max-width: 1000px) {
                padding: 16px 24px;
                width: 100% !important;
                margin: 0;
            }
            &:not(:first-child) {
                padding-left: 24px;
                @media (max-width: 1000px) {
                    margin: 0;
                }
            }
            &:not(:last-child) {
                border-right: 1px solid #eff0f6;
            }
            &Id {
                color: #0d41e6 !important;
                font-weight: 600 !important;
                letter-spacing: 1 !important;
            }
            &Date {
                width: 120%;
            }
        }
    }
    &Buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 1000px) {
            flex-direction: column-reverse;
        }
    }
    &Button {
        all: unset;
        border-radius: 8px;
        color: #4e4b66;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 14px 0;
        text-align: center;
        cursor: pointer;
        width: 50%;
        @media (max-width: 1000px) {
            width: 100%;
        }
        &Blue {
            all: unset;
            border-radius: 8px;
            color: white;
            background-color: #0d41e6;
            font-size: 16px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 1px;
            text-transform: uppercase;
            padding: 14px 18px;
            cursor: pointer;
            flex: 1;
            text-align: center;
            @media (max-width: 1000px) {
                flex: 1;
                margin: 0 !important;
            }
        }
    }
    &StatusDot {
        background: #00966d;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin-right: 4px;
    }
    &Etherscan {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #0d41e6;
        font-weight: 600;
        font-size: 13px;
        line-height: 28px;
        letter-spacing: 1px;
        text-transform: uppercase;
        @media (max-width: 1000px) {
            font-size: 11px;
        }
        span {
            margin-right: 10px;
        }
    }
}

.success,
.review {
    position: relative;
    @media (max-width: 1000px) {
        flex-shrink: 0;
    }
    &::before {
        content: '';
        position: absolute;
        width: calc(100% - 16px);
        height: 1px;
        background: #eff0f7;
        top: 50%;
        left: 8px;
        display: none;
        @media (max-width: 1000px) {
            display: block;
        }
    }
}

.buttonContainer {
    display: flex;
}

.kyc {
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    @media (max-width: 1000px) {
        min-height: 100vh;
    }
    &Image {
        width: 100%;
        background: #fde9c3;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;
        @media (max-width: 500px) {
            padding: 20px;
        }
    }
    &Bottom {
        padding: 32px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 1px;
        color: #14142b;
        margin: 0;
        @media (max-width: 420px) {
            font-size: 17px;
        }
    }
    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #4e4b66;
        margin: 0;
        @media (max-width: 420px) {
            font-size: 12px;
        }
    }
    &Button {
        all: unset;
        text-transform: uppercase;
        background: #0d41e6;
        border-radius: 8px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px;
        color: white;
        padding: 16px 30px;
        cursor: pointer;
        @media (max-width: 420px) {
            font-size: 14px;
        }
    }
}

.closeButton {
    background: transparent;
    margin: 0;
    padding: 0;
    border: none;
}
