.container {
    display: inline-flex;
    height: 20px;
    align-items: center;
}

.tooltipContainer {
    display: inline-flex;
    flex-shrink: 0;
    margin-left: 5px;
    position: relative;
    cursor: pointer;
    img {
        width: 14px;
        height: 14px;
    }
    &:hover {
        .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }
    @media (max-width: 768px) {
        position: static;
    }
}

.tooltip {
    position: absolute;
    background: white;
    color: black;
    border: 1px solid #0d41e6;
    padding: 5px 10px;
    left: 100%;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out;
    z-index: 10;
    min-width: 400px;
    font-weight: 400;
    @media (max-width: 768px) {
        min-width: 320px;
        left: 0;
    }
    @media (max-width: 450px) {
        min-width: 280px;
    }
}

