.auth {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 110px;
    padding-top: 70px;
    height: 100%;
    @media (max-width: 1000px) {
        padding: 16px;
        height: 100%;
    }
    &Header {
        text-align: center;
        color: #14142b;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.75px;
        line-height: 32px;
        @media (max-width: 1000px) {
            color: #4e4b66;
            font-size: 14px;
            line-height: 21px;
        }
    }
    &Form {
        display: flex;
        justify-content: space-around;
        width: 90%;
        margin: 0 auto;
    }
    &Input {
        color: #4e4b66;
        font-weight: 600;
        line-height: 28px;
        font-size: 16px;
        text-align: center;
        width: 47px;
        height: 56px;
        border-radius: 8px;
        border: 2px solid #a0a3bd;
        background: rgba(239, 240, 247, 0.5);
    }
    &Resend {
        text-align: center;
        color: #a0a3bd;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.2px;
        font-weight: 600;
        @media (max-width: 1000px) {
            position: fixed;
            left: 0;
            bottom: 120px;
            width: 100%;
        }
    }
}

.errorMessage {
    font-size: 14px;
    color: #f44747;
    text-align: center;
    font-weight: 500;
}

.loading {
    display: block;
    height: 25px;
    margin: 0 auto;
    margin-top: 16px;
}
