.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &Top {
        padding: 32px;

        @media (max-width: 550px) {
            padding: 16px;
            overflow-y: auto;
        }
    }
    &Header {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.75;
        font-weight: 600;
        color: #14142b;
        margin: 0;
    }
    &Text {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.2;
        color: #4e4b66;
        margin: 0;
        margin-top: 16px;
        a {
            font-weight: 600;
            text-decoration: none;
            color: #0d41e6;
        }

        @media (max-width: 550px) {
            font-size: 14px;
            margin-top: 0;
        }
    }
    &Bottom {
        padding: 32px;
        padding-top: 0;
        button {
            all: unset;
            background-color: #777;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 1px;
            padding: 10px 30px;
            color: #f7f7fc;
            text-transform: uppercase;
            text-align: center;
            cursor: pointer;
        }
    }
}

.forgotPassword {
    text-decoration: none;
    color: black;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2;
    font-weight: 600;

    @media (max-width: 550px) {
        font-size: 14px;
    }
}

.errorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 32px;
}

.errorLink {
    margin-bottom: 16px;
    font-size: 12px;
    color: #aaa;
}

.errorMessage {
    width: 100%;
    background-color: #fff6fa;
    color: #f44747;
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2;
    flex-shrink: 0;
    padding: 18px 0;
}

.fromSignUp {
    background-color: #f4f9f7;
    color: #37a23a;
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2;
    padding: 18px;
    margin-right: 32px;
    margin-left: 32px;

    @media (max-width: 550px) {
        margin-right: 16px;
        margin-left: 16px;
        font-size: 12px;
    }
}

.recaptchaHidden {
    font-size: 12px;
    color: #a0a3bd;
    a {
        color: #0d41e6;
    }
}

.grecaptcha-badge {
    visibility: visible;
}