.uploadTextContainer {
    display: flex;
    align-items: center;
}

.uploadText {
    font-size: 12px;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.underline {
    text-decoration: underline;
}