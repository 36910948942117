.resend {
    color: #a0a3bd;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    &Pointer {
        cursor: pointer;
    }
}
