@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import './variables';
* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    min-width: 0;
    min-height: 0;
    outline: none;
}

body {
    margin: 0;
    padding: 0;
    background: #f7f7fc;
}

.simplebar-scrollbar::before {
    background-color: #ccc !important;
}

.appInside {
    .simplebar-scrollbar::before {
        background-color: #777 !important;
    }
}

.full-content {
    .simplebar-content {
        height: 100%;
    }
}

.row {
    width: 100%;
    margin-bottom: 20px;
    &Grid4 {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(4, 1fr);
        @media (max-width: 1600px) {
            grid-template-columns: repeat(6, 1fr);
        }
    }
    &Grid2 {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
    &-column {
        width: 100%;
        height: 100%;
        &3 {
            grid-column: span 3;
            @media (max-width: 1600px) {
                grid-column: span 4;
            }
        }
        &1 {
            grid-column: span 1;
            @media (max-width: 1600px) {
                grid-column: span 2;
            }
        }
        &-md1 {
            @media (max-width: 1400px) {
                grid-column: 1 / -1;
            }
        }
        &-mobile1 {
            @media (max-width: 1200px) {
                grid-column: 1 / -1;
            }
        }
        &-votingMobile {
            @media (max-width: 1000px) {
                grid-column: span 2;
            }
        }
    }
}

.text--gray {
    color: #a0a3bd;
}

.text--bold {
    font-weight: 600;
}

.walletButton {
    all: unset;
    background: #f7f7fc;
    border-radius: 4px;
    min-width: 88px;
    padding: 0 8px;
    height: 44px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;

    span {
        color: #6e7191;
        font-weight: 500;
        letter-spacing: 0.2px;
        transition: all 0.3s ease-in-out;
        font-size: 12px;
    }

    & > div > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    svg {
        margin-right: 8px;
        path {
            transition: all 0.3s ease-in-out;
            fill: #6e7191;
        }
    }

    &:hover span {
        color: #14142b;
    }

    &:hover svg path {
        fill: #14142b;
    }

    @media (max-width: 1300px) {
        min-width: 80px;
        height: 35px;
        margin-left: 8px;

        &:first-child {
            margin-left: 0;
        }

        span {
            font-size: 12px;
        }

        svg {
            height: 16px;
            width: 16px;
            path {
                transition: all 0.3s ease-in-out;
                fill: #6e7191;
            }
        }
    }

    @media (max-width: 1000px) {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        min-width: auto;
        padding: 0;
        background: #EFF0F6;
        svg {
            margin: 0;
        }
        span {
            display: none;
        }
    }
}

.special {
    color: #0d41e6;
    &--pink {
        color: #fe8fa6;
    }
}

.modal-body {
    position: absolute;
    background: white;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(#14142b, 0.85);
    z-index: 100000;
}

.select-active {
    .arrow-stroke {
        stroke: #000;
    }
}

.modalHeader {
    .arrow-left-alt {
        fill: #fff;
    }
}

.link-icon {
    @media (max-width: 650px) {
        fill: #6e7191;
    }
}

.profileLink {
    transition: 0.3s ease-in-out;
    .profileIcon,
    .profileIconStroke {
        transition: 0.3s ease-in-out;
    }
    &:hover {
        .profileIcon {
            fill: #0d41e6;
        }
        .profileIconStroke {
            stroke: #0d41e6;
        }
    }
}

.signOutWrapper {
    transition: 0.3s ease-in-out;
    .signOut {
        transition: 0.3s ease-in-out;
    }
    &:hover {
        padding-left: 10px;
        .signOut {
            stroke: white;
        }
    }
}

.container {
    padding: 128px;
    @media (max-width: 1400px) {
        padding: 64px;
    }
    @media (max-width: 1250px) {
        padding: 32px;
    }
    @media (max-width: 1000px) {
        padding: 16px;
    }
    &-horizontal {
        padding: 0 128px 0 128px;
        @media (max-width: 1400px) {
            padding: 0 64px 0 64px;
        }
        @media (max-width: 1250px) {
            padding: 0 32px 0 32px;
        }
        @media (max-width: 1000px) {
            padding: 0 16px 0 16px;
        }
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.protectedRoute ~ .recaptchaContainer .grecaptcha-badge {     
    visibility: visible;
}

.coinbase-commerce-button {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: -100px;
}

.advcashForm {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.home,
.wallet {
    position: static;
    left: 20px;
    top: 20px;
    right: 20px;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: all 300ms ease-in-out;
    .ReactModal__Content {
        transform: translate(-50%, -50%) scale(0.9);
        transition: all 300ms ease-in-out;
    }
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    .ReactModal__Content--after-open {
        transform: translate(-50%, -50%) scale(1);
    }
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    .ReactModal__Content--before-close {
        transform: translate(-50%, -50%) scale(0.9);
    }
}

.weglot-container {
    position: fixed;
    bottom: 0;
    left: 20px;
}

.errorScreen {
    background: #0d41e6;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    h2 {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 35px;
        margin: 0;
        text-align: center;
    }
    button {
        padding: 10px 40px;
        background: transparent;
        border: 2px solid white;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        margin-top: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        transition: 0.3s ease-in-out;
        &:hover {
            background: white;
            color: #0d41e6;
        }
    }
}

.error-modal {
    padding: 45px 70px;
    text-align: center;
    color: #0d41e6;
    h2 {
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 0;
    }
    p {
        margin: 20px 0;
    }
    button {
        padding: 10px 30px;
        background: white;
        border: 2px solid #0d41e6;
        color: #0d41e6;
        border-radius: 5px;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        &:hover {
            background: #0d41e6;
            color: white;
        }
    }
}

.error-modal-overlay {
    z-index: 100001 !important;
}

.light {
    .videoIcon {
        fill: #fcfcfc;
    }
}

.dark {
    .videoIcon {
        fill: #0d41e6;
    }
}
