.modal {
    height: 700px;
    width: 600px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: 1000px) {
        width: 100%;
        min-height: 100%;
        height: stretch;
        height: -moz-fit-content;
        height: -webkit-fill-available;
        position: relative;
    }
    &Content {
        flex: 1;
        overflow: auto;
    }
}
