.error {
    width: 100%;
    height: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: #d10303;
    letter-spacing: 1px;
    font-weight: 500;
}
