.overlay {
    display: grid;
    grid-template-columns: 1fr;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    @media (max-width: 1100px) {
        grid-template-columns: 1fr;
    }
}

.carousel {
    display: none;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    flex-direction: column;
}

.logo {
    margin-bottom: 30px;
    transform: scale(1.3);
    path {
        fill: white;
    }
}
