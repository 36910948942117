.container {
    margin: 15px 0;
}

.title {
    display: inline-block;
    margin-bottom: 10px;
    color: #0d41e6;
    font-weight: bold;
    @media (max-width: 500px) {
        font-size: 14px;
        margin-bottom: 5px;
    }
}

.label {
    color: #4e4b66;
    margin-left: 5px;
    font-weight: 500;
    @media (max-width: 500px) {
        font-size: 12px;
    }
}

.checkboxContainer {
    margin: 10px 0;
    @media (max-width: 500px) {
        margin: 5px 0;
    }
}

.inputContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 10px;
}


.moreButton {
    margin-top: 10px;
    border: 1px solid #0d41e6;
    background: #fff;
    border-radius: 10px;
    color: #0d41e6;
    font-size: 20px;
    cursor: pointer;
    transition: .3s ease-in-out;
    font-weight: 500;
    &:hover {
        background: #0d41e6;
        color: #fff;
    }
}

.error {
    font-size: 12px;
    color: red;
    font-weight: 500;
}