.button {
    padding: 15px 30px;
    margin-right: 10px;
    border-radius: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    text-decoration: none;
    color: #6e7191;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    @media (max-width: 2200px) {
        font-size: 0.9em;
        padding: 10px 20px;
    }
    @media (max-width: 1100px) {
        font-size: 0.7em;
        padding: 10px 15px;
        margin-top: 5px;
    }
}

.special {
    border: 2px solid #0d41e6;
    color: #0d41e6;
    &:hover {
        background: #0d41e6;
        color: white;
    }
}
.primary {
    background: #0d41e6;
    color: white;
}
.secondary {
    background: #fe8fa6;
    color: white;
}
