.success {
    &Top {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        padding: 0 60px;
        @media (max-width: 550px) {
            padding: 0 16px;
        }
    }
    &Header {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 1px;
        text-align: center;
        color: #14142b;
        margin-bottom: 32px;
        @media (max-width: 550px) {
            font-size: 22px;
            text-align: left;
        }
    }
    &Text {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.3px;
        text-align: center;
        color: #4e4b66;
        margin-top: 32px;
        @media (max-width: 550px) {
            font-size: 12px;
            text-align: left;
        }
    }
    &Bottom {
        padding: 32px;
        border-top: 2px solid #eff0f7;
        button {
            all: unset;
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
            color: white;
            background: #0d41e6;
            border-radius: 8px;
            height: 64px;
            width: 100%;
            cursor: pointer;
        }
    }
}
