.input {
    border: none;
    background: #eff0f7;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    color: #4e4b66;
    font-weight: 500;
    min-width: 350px;
    max-width: 100%;
    &::placeholder {
        color: #a0a3bd;
    }
    &:first-child {
        margin-top: 0;
    }
    @media(max-width: 500px) {
        min-width: auto;
    }
}

.error {
    border: 1px solid red;
}

.errorMsg {
    font-size: 12px;
    color: red;
    margin-top: 5px;
    font-weight: 500;
}