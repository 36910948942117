
.next {
    all: unset;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-transform: uppercase;
    display: flex !important;
    align-items: center;
    user-select: none;
    color: #0d41e6;
    padding: 16px 24px;
    border: 2px solid #0d41e6;
    border-radius: 8px;
    margin-left: auto;
    @media (max-width: 1000px) {
        margin-top: 0;
        width: 100%;
        justify-content: center;
        padding: 16px 0;
    }
}

.buttons {
    flex-direction: row;
    justify-content: center;
}

.primary {
    background: #0d41e6;
    color: white;
}

.nextDisable {
    opacity: 0.5;
    cursor: default;
}

.loading {
    height: 28px;
    color: #0d41e6;
    margin: 0 43px;
}
