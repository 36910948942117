.container {
    margin-top: 20px;
}

.dropdownBtnRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background: #ededed;
    }
}

.dropdownBtn {
    background: #0d41e6;
    border-radius: 50%;
    border: none;
    width: 30px;
    height: 30px;
    position: relative;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}

.showDropdown {
    display: block;
}

.hideDropdown {
    display: none;
}

.value {
    color: #37a23a;
}

.highlight {
    span {
        font-weight: 600;
    }
}

.highlightRow {
    border: 1px solid #0d41e6;
    border-bottom: 1px solid #0d41e6 !important;
    margin: 10px 0;
    border-radius: 10px;
}
