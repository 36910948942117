
.linkRow {
    display: flex;
    justify-content: flex-end;
    filter: grayscale(100%);
    a {
        text-decoration: none;
        color: #0d41e6;
        font-weight: 600;
        text-transform: uppercase;
        margin: 10px 0;
        display: flex;
        align-items: center;
        &:hover {
            text-decoration: underline;
        }
    }
}

.loading {
    position: absolute;
    inset: 0;
    background: rgba(#000, 0.2);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    img {
        width: 50px;
    }
}

.dataLoading {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 50px;
    }
}

.loadingActive {
    opacity: 1;
    visibility: visible;
}

.sign {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #4e4b66;
    font-weight: 500;
    text-align: right;
}

.stepsContainer {
    margin-top: 20px;
}