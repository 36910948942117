.footer {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 15px;
    color: #9B9B9B;
    a {
        color: #9B9B9B;
    }
}