.auth {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &Header {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.75;
        text-align: center;
        color: #14142b;
        @media (max-width: 550px) {
            font-size: 14px;
            line-height: 21px;
            color: #4e4b66;
            margin-bottom: 16px;
        }
    }
    &Footer {
        height: 128px;
        padding: 0 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 2px solid #eff0f7;
        &Back {
            all: unset;
            display: flex !important;
            color: #4e4b66;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 1px;
            font-weight: 700;
            text-transform: uppercase;
            cursor: pointer;
            align-items: center;
        }
        &Info {
            display: flex;
            align-items: center;
            &Text {
                color: #4e4b66;
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.1px;
                margin-left: 8px;
            }
        }
        @media (max-width: 550px) {
            padding: 0 8px;
        }
    }
}

.top {
    padding: 0 60px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 470px) {
        padding: 0 24px;
    }
    @media (max-width: 400px) {
        padding: 0 8px;
    }
}

.bottom {
    &Spinner {
        height: 25px;
        margin: 0 auto;
        display: block;
        margin-bottom: 32px;
    }
}

.tooltip {
    background-color: #4e4b66 !important;
    opacity: 1 !important;
    color: #fcfcfc;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: 0.3 !important;
    width: 424px !important;
    border-radius: 8px !important;
    text-align: center !important;
    @media (max-width: 550px) {
        width: 70% !important;
    }
}
