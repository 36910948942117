.table {
    height: 1px;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    text-align: right;
    font-weight: 500;
    color: #14142b;
    border-collapse: collapse;
    @media (max-width: 450px) {
        font-size: 12px;
    }
    td {
        padding: 5px;
    }
    td:nth-child(2) {
        color: #37a23a;
    }
    td:first-child {
        text-align: left;
    }
    tr {
        height: 100%;
        border-top: 1px solid #e6e6e6;
        &:first-child {
            border-top: none;
        }
    }
}

.highlight {
    border-top: none !important;
    td {
        padding: 0;
        padding-top: 10px;
        line-height: 1.5;
        > div {
            height: 100%;
            padding: 5px;
            border-top: 1px solid #0d41e6;
            border-bottom: 1px solid #0d41e6;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        
        &:first-child {
            > div {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                padding-right: 0;
                border-left: 1px solid #0d41e6;
                justify-content: flex-start;
            }
        }
        &:last-child {
            > div {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                padding-left: 0;
                border-right: 1px solid #0d41e6;
            }
        }
    }
}

.inline {
    display: inline;
}

.head {
    font-size: 12px;
    @media (max-width: 450px) {
        font-size: 10px;
    }
    th {
        font-weight: 500;
        text-transform: uppercase;
        color: #9b9b9b;
        padding-bottom: 10px;
        &:first-child {
            text-align: left;
        }
    }
}

.alignRight {
    text-align: right;
}

.labelTooltip {
    display: inline;
    align-items: center;
    position: relative;
}
