.container {
    margin-top: 20px;
}

.btnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

