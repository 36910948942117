.checkbox {
    display: none;
    &Text {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1;
        font-weight: 500;
        text-align: left;
        @media (max-width: 1000px) {
            margin: 0;
        }
        a {
            text-decoration: none;
            color: #0d41e6;
        }
        &Error, &Error span {
            color: #f44747 !important;
            a {
                color: #d10303;
            }
        }
        
    }

    &Label {
        display: flex;
        align-items: center;
        @media (max-width: 1000px) {
            margin-bottom: 5px;
        }
        img {
            width: 20px;
            cursor: pointer;
            flex-shrink: 0;
            margin-right: 5px;
        }
    }
}
