.btnContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    &:last-child {
        .position {
            &::before {
                display: none;
            }
        }
    }
}

.button {
    padding: 7px;
    background: #fff;
    border: 1px solid #0d41e6;
    color: #0d41e6;
    font-weight: 500;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 100%;
    text-align: left;
}

.position {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    background: #9f9f9f;
    color: white;
    font-size: 12px;
    flex-shrink: 0;
    position: relative;
    font-weight: 700;
    border: 1px solid #9f9f9f;
    &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 50px;
        background: #e6e6e6;
        top: calc(100% + 1px);
    }
}

.icon {
    height: 16px;
    flex-shrink: 0;
}

.warning {
    .position {
        background: #0d41e6;
        border-color: #0d41e6;
    }
    button {
        border-color: #0d41e6;
        color: #0d41e6;
    }
}

.pending {
    .position {
        border-color: #0d41e6;
        background: white;
        color: #0d41e6;
    }
    path {
        stroke: #0d41e6;
    }
}

.passed {
    .position {
        background: #37a23a;
        color: white;
        border-color: #37a23a;
    }
    button {
        border-color: #37a23a;
        color: #37a23a;
        cursor: default;
    }
    path {
        stroke: #37a23a;
    }
}

.progress {
    .position {
        border-color: #0d41e6;
        background: white;
        color: #0d41e6;
    }
    path {
        transform-origin: center;
        animation-name: spin;
        animation-duration: 1500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
        stroke: #0d41e6;
    }
}

.waiting {
    button {
        color: #9f9f9f;
        border-color: #9f9f9f;
        cursor: default;
    }
    path {
        stroke: #9f9f9f;
    }
}

.error {
    button {
        color: #d10303;
        border-color: #d10303;
    }
    .position {
        background: #d10303;
        border-color: #d10303;
    }
}

.pointer {
    cursor: pointer !important;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}