.modal {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 700px;
    @media (max-width: 1000px) {
        width: 100%;
        max-width: 100%;
        height: 100%;
        position: relative;
        overflow: auto;
    }
}

.content {
    padding: 20px;
    @media (max-width: 1000px) {
        flex: 1;
        overflow: auto;
    }
}