.signIn {
    width: 500px;
    max-height: 95%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;

    @media (max-width: 550px) {
        width: 100vw;
        max-height: 100%;
        height: 100vh;
        border-radius: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
