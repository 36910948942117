.header {
    height: 80px;
    width: 100%;
    background-color: #0d41e6;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    position: relative;
    z-index: 1000;
    @media (max-width: 1000px) {
        height: 57px;
        border-radius: 0;
        justify-content: flex-start;
        padding: 0 22px;
        flex-shrink: 0;
    }
    span {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.75px;
        color: white;
        @media (max-width: 1000px) {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.2px;
        }
    }
    .x {
        all: unset;
        height: 24px;
        cursor: pointer;
        @media (max-width: 1000px) {
            display: none;
        }
    }
}

.exitMobile {
    all: unset;
    display: flex;
    align-items: center;
    margin-right: 22px;
    cursor: pointer;
    @media (min-width: 1000px) {
        display: none !important;
    }
    div {
        height: 100%;
        display: flex;
    }
}

.gray {
    text-transform: uppercase;
}

.dot {
    opacity: 0.3;
}
