.mail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &Top {
        padding: 32px;
        padding-bottom: 0;
        @media (max-width: 550px) {
            overflow-y: auto;
            padding: 16px;
        }
    }

    &Header {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.75px;
        color: #14142b;
        margin: 0;
        margin-bottom: 16px;
        @media (max-width: 550px) {
            font-size: 22px;
            margin-bottom: 0px;
        }
    }

    &Text {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: rgba(#000000, 0.72);
        @media (max-width: 550px) {
            font-size: 14px;
            color: #4e4b66;
            letter-spacing: 0.3px;
            margin: 0;
        }
    }

    &Image {
        height: 220px;
        display: block;
        margin: 36px auto;
        @media (max-width: 550px) {
            height: 160px;
        }
    }

    &Info {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-align: center;
        padding: 8px 16px;
        border-radius: 8px;
        margin-top: 26px;
        &Success {
            color: #37a23a;
            background-color: #f4f9f7;
        }
    }
    &Bottom {
        padding: 32px;
        padding-top: 0;
        display: flex;
        justify-content: space-between;
        .next {
            all: unset;
            cursor: pointer;
            letter-spacing: 1px;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            text-transform: uppercase;
            display: flex !important;
            align-items: center;
            user-select: none;
            padding: 16px 24px;
            border-radius: 8px;
            margin-left: 64px;
            transition: 0.2s all ease-in-out;
            &Disabled {
                opacity: 0.4;
                cursor: default;
            }
        }

        .back {
            all: unset;
            cursor: pointer;
            letter-spacing: 1px;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            text-transform: uppercase;
            display: flex !important;
            align-items: center;
            user-select: none;
            color: #4e4b66;
        }
    }
}
