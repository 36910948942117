.nav {
    background: #0d41e6;
    padding: 0 24px;
    height: 57px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    .logo {
        display: flex;
        align-items: center;
        color: white;
        font-weight: 500;
        width: 250px;
        text-decoration: none;
        @media (max-width: 1200px) {
            width: 146px;
        }
        img {
            margin-right: 8px;
            height: 32px;
            @media (max-width: 1000px) {
                display: none;
            }
        }
    }
}

.logoutBtn {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.logoutImage {
    margin-right: 5px;
}
