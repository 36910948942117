.container {
    position: absolute;
    inset: 0;
    background: rgba(#222, 0.65);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 50px 120px;
    backdrop-filter: blur(3px);
    @media (max-width: 600px) {
        padding: 50px 100px;
    }
    @media (max-width: 450px) {
        padding: 50px 20px;
    }
}

.icon {
    background: #f3f3f8;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        margin-top: 5px;
        path {
            fill: #000;
        }
    }
}

.text {
    color: #fff;
    text-shadow: 2px 2px 5px rgba(#000, 0.3);
}