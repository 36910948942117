.back {
    all: unset;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-transform: uppercase;
    display: flex !important;
    align-items: center;
    user-select: none;
    color: #4e4b66;
    margin-right: auto;
    width: 100%;
    p {
        display: inline-block;
    }
    @media (max-width: 1000px) {
        margin-top: 0;
    }
}

.backContentButton {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

