.change {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &Top {
        padding: 32px;
        @media (max-width: 550px) {
            overflow-y: auto;
            padding: 16px;
        }
    }
    &Header {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.75px;
        color: #14142b;
        font-weight: 600;
        margin-top: 0;
        @media (max-width: 550px) {
            font-size: 22px;
            margin-bottom: 0;
        }
    }

    &Text {
        font-size: 16px;
        line-height: 21px;
        color: rgba(#000000, 0.72);
        font-weight: 500;
        @media (max-width: 550px) {
            font-size: 14px;
            margin-top: 0;
        }
    }

    &Requirements {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #a0a3bd;
        @media (max-width: 550px) {
            font-size: 12px;
            letter-spacing: 0.1px;
        }
    }

    &Bottom {
        padding: 32px;
        padding-top: 0;
        button {
            all: unset;
            background-color: #777;
            border-radius: 8px;
            padding: 10px 30px; 
            text-align: center;
            font-weight: 500;
            letter-spacing: 1px;
            font-size: 16px;
            text-transform: uppercase;
            color: white;
            cursor: pointer;
        }
    }
}
