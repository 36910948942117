.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.error {
    font-size: 12px;
    color: #d10303;
    font-weight: 500;
}

.warning {
    font-size: 12px;
    color: #f2994a;
    font-weight: 500;
}

.uploadContainer {
    border: 1px dashed #4e4b66;
    border-radius: 10px;
    width: 250px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.sendBtnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}