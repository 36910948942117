.input {
    width: 100%;
    border: unset;
    background-color: #eff0f7;
    border-radius: 8px;
    height: 56px;
    color: #4e4b66;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    font-weight: 600;
    padding: 0 24px;
    padding-right: 55px;
    transition: all 0.1s ease-in-out;
    border: 2px solid #eff0f7;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &::placeholder {
        color: #a0a3bd;
    }
    &Disabled {
        opacity: 0.6;
    }
    &Error {
        border: 2px solid #f44747;
        background-color: #fff6fa;
        color: #14142b;
    }
}

.inputWrapper {
    position: relative;
}

.append {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    cursor: pointer;
    &Text {
        letter-spacing: 0.2px;
        color: #4e4b66;
        font-weight: 600;
        transition: all 0.1s ease-in-out;
        &Error {
            color: #f44747;
        }
    }
    &Image {
        height: 20px;
    }
}

.prepend {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    fill: #000000;
    transition: all 0.1s ease-in-out;
    svg {
        fill: #000000;
        transition: all 0.1s ease-in-out;
        path {
            fill: #000000;
            transition: all 0.1s ease-in-out;
        }
    }
    &Error {
        svg {
            fill: #d10303;
            path {
                fill: #d10303;
            }
        }
    }
}

.prependPadding {
    padding-left: 50px;
}

.hint {
    color: #6e7191;
    font-size: 13px;
    letter-spacing: 0.1px;
    text-align: right;
    font-weight: 600;
    margin: 0;
    line-height: 19.5px;
    margin-top: 6px;
}

.errorMessage {
    color: #d10303;
    font-size: 13px;
    letter-spacing: 0.1px;
    text-align: left;
    font-weight: 600;
    margin: 0;
    line-height: 19.5px;
    margin-top: 6px;
}

.label {
    position: absolute;
    color: #a0a3bd;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.1px;
    left: 24px;
    top: 10px;
}

.inputLabel {
    padding-top: 45px;
    padding-bottom: 25px;
    opacity: 1;
}
