.body {
    padding: 20px;
    max-width: 600px;
    font-weight: 500;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.iconContainer {
    width: 130px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.alert {
    width: 120px;
}