.buttonContainer {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.special {
    color: #0d41e6;
}

.btn {
    background: #0d41e6;
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    text-decoration: none;
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: 600;
    @media (max-width: 1100px) {
        font-size: 0.7em;
        padding: 10px 15px;
        margin-top: 5px;
    }
}