.hash {
    display: block;
    margin: 10px 0;
    font-size: 14px;
    text-align: center;
    color: #0d41e6;
    font-weight: 500;
}

.btnContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;

}

.logoutBtn {
    background: #f44747;
    color: #fff;
}


.heading {
    color: #37a23a !important;
}