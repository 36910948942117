.label {
    display: inline-block;
    color: #0d41e6;
    font-weight: 600;
    margin: 10px 0;
}

.btnContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}