.slide {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &STC {
        font-weight: 700;
        font-size: 32px;
        line-height: 37.5px;
        letter-spacing: 1px;
        color: #000;
        margin: 0;
    }
    img {
        width: 85%;
        @media (max-width: 1600px) {
            width: 100%;
        }
    }
    &Header {
        font-weight: 700;
        font-size: 48px;
        line-height: 62.4px;
        margin: 0;
        @media (max-width: 1600px) {
            font-size: 32px;
            line-height: 46.4px;
        }
    }
    &Text {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.75;
        margin: 0;
        margin-top: 24px;
        max-width: 680px;
        @media (max-width: 1600px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
