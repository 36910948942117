.container {
    width: 650px;
    border: 1px solid #ccc;
    padding: 10px 32px;
    margin-top: 50px;
    background: white;
    position: relative;
    h2 {
        font-size: 18px;
    }
    p {
        margin: 10px 0;
        padding: 0;
        font-size: 14px;
        text-align: justify;
        color: #4e4b66;
    }
    @media (max-width: 450px) {
        padding: 10px;
    }
}

.logoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
